import { useEffect, useState } from "react";

const useLocalStorageObserver = (key: string) => {
  const [value, setValue] = useState(localStorage.getItem(key) || "");

  useEffect(() => {
    const handleStorageChange = () => {
      setValue(localStorage.getItem(key) || "");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => window.removeEventListener("storage", handleStorageChange);
  }, [key]);

  return value || localStorage.getItem(key);
};

export default useLocalStorageObserver;
