import { useQuery } from "@apollo/client";
import countryThreeCharCode from "data/countryThreeCharCode";
import cloneDeep from "lodash/cloneDeep";
import QUOTE_COUNTRIES_LIST from "queries/QnB/getQuoteCountriesList";
import { useMemo } from "react";
import { useIntl } from "react-intl";

interface QuoteCountryBase {
  isBilling: boolean;
  isDestination: boolean;
  isOrigin: boolean;
  isLeadQualificationAvailable: boolean;
  label: string;
  preferredAddressInputType: string;
  _id: string;
  __typename: "QuoteCountry";
  value: string;
}

export interface QuoteCountry extends QuoteCountryBase {
  code3: string;
  originalLabel: string;
}

const extendQuoteCountryData = (country: QuoteCountryBase): QuoteCountry => ({
  ...country,
  label: `${country.label} (${country.value === "DX" ? "AE" : country.value})`,
  code3:
    countryThreeCharCode[country.value as keyof typeof countryThreeCharCode],
  originalLabel: country.label,
});

const sortQuoteCountries = (
  countryA: QuoteCountryBase,
  countryB: QuoteCountryBase
) => countryA.label.localeCompare(countryB.label);

const emptyListFallback = [] as QuoteCountryBase[];

export const useQuoteCountryOptions = () => {
  const intl = useIntl();
  const { data } = useQuery(QUOTE_COUNTRIES_LIST, {
    errorPolicy: "all",
    variables: {
      locale: intl.locale,
    },
    fetchPolicy: "cache-and-network",
  });

  const items: QuoteCountryBase[] =
    data?.getQuoteCountries || emptyListFallback;

  return useMemo(
    () => cloneDeep(items).sort(sortQuoteCountries).map(extendQuoteCountryData),
    [items]
  );
};
