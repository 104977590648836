import omit from "lodash/omit";
import * as C from "./constants";

export const purchaseOrdersReducer = (
  state: C.PurchaseOrdersState = C.initialState,
  action: C.PurchaseOrdersAction
): C.PurchaseOrdersState => {
  switch (action.type) {
    case C.PurchaseOrdersActionType.SET_PURCHASE_ORDERS_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.activeTab,
      };
    }
    case C.PurchaseOrdersActionType.SET_PURCHASE_ORDERS_QUERY: {
      return {
        ...state,
        [state.activeTab]: {
          ...state[state.activeTab],
          query: action.query,
        },
      };
    }
    case C.PurchaseOrdersActionType.SET_MILESTONES_DATE_RANGE_FILTERS: {
      const filteredRequestObject = omit(state[state.activeTab], [
        "arrivalCombined",
        "deliveryCombined",
        "departureCombined",
        "pickupCombined",
      ]);

      return {
        ...state,
        [state.activeTab]: {
          ...filteredRequestObject,
          ...(action.milestonesDateRangeFilters ?? {}),
        },
      };
    }
    case C.PurchaseOrdersActionType.SET_PURCHASE_ORDERS_LIST_SORT: {
      return {
        ...state,
        [state.activeTab]: {
          ...state[state.activeTab],
          order: [{ field: action.field, order: action.order }],
        },
      };
    }
    case C.PurchaseOrdersActionType.SET_PURCHASE_ORDERS_LIST_FILTERS: {
      if (action.isNewLayoutEnabled) {
        return {
          ...state,
          [state.activeTab]: action.filters,
        };
      }
      const { favorite: _, ...restFilters } = state[state.activeTab];

      return {
        ...state,
        [state.activeTab]: { ...restFilters, ...action.filters },
      };
    }
    case C.PurchaseOrdersActionType.SET_FAVORITE_FILTER: {
      const { favorite: _, ...restFilters } = state[state.activeTab];

      return {
        ...state,
        [state.activeTab]: {
          ...restFilters,
          ...(action.isFavorite ? { favorite: true } : {}),
        },
      };
    }
    case C.PurchaseOrdersActionType.SET_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: action.scrollPosition,
      };
    }
    case C.PurchaseOrdersActionType.SET_VISIBLE_TABLE_DATES: {
      return {
        ...state,
        visibleDates: action.visibleDates,
      };
    }
    default:
      return state;
  }
};
