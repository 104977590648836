import { PackageTypeCodes } from "./masterDataManagement";

export enum Product {
  AFR = "AFR",
  OFR = "OFR",
  ROAD = "ROAD",
  RAIL = "RAIL",
}

export enum PaymentTerm {
  COLLECT = "COLLECT",
  PREPAID = "PREPAID",
  CROSSTRADE = "CROSSTRADE",
  UNDEFINED = "UNDEFINED",
}

export enum AddressInputType {
  POSTALCODE_MAPS_API = "POSTALCODE_MAPS_API",
  POSTALCODE_MANUAL = "POSTALCODE_MANUAL",
  LOCALITY_LOCREG_LIST = "LOCALITY_LOCREG_LIST",
  POLYGON_MAPS_API = "POLYGON_MAPS_API",
  LOCALITY_MANUAL = "LOCALITY_MANUAL",
  LOCALITY_MAPS_API = "LOCALITY_MAPS_API",
  STATION_CODE = "STATION_CODE",
}

export interface Address {
  addressLine1?: string;
  addressLine2?: string;
  countryCode?: string;
  postalCode?: string;
  city?: string;
  stateProvince?: string;
  preferredAddressInputType?: AddressInputType;
  isCustomerVisible?: boolean;
  addressBookId?: string;
  __typename: "Address";
}

export interface ContactDetails {
  name?: string;
  phone?: string;
  email?: string;
  contactId?: string;
  __typename: "BookingContactDetails";
}

export interface Party {
  type: PartyType;
  referredType?: PartyType;
  accountNumber?: string;
  companyName?: string;
  address?: Address;
  contactDetails?: ContactDetails;
  useTerminal: boolean;
  terminalCode?: string;
  handlingStation?: string;
  __typename: "Party";
}

export enum PartyType {
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
  SHIPPER = "SHIPPER",
  CONSIGNEE = "CONSIGNEE",
  BILLING = "BILLING",
  CUSTOMER = "CUSTOMER",
  NOTIFY = "NOTIFY",
}

export enum WeightUnit {
  GRM = "GRM",
  KGM = "KGM",
  LBR = "LBR",
  ONZ = "ONZ",
  TNE = "TNE",
}

export enum VolumeUnit {
  YDQ = "YDQ",
  MTQ = "MTQ",
  LTR = "LTR",
  INQ = "INQ",
  GLL = "GLL",
  FTQ = "FTQ",
  DMQ = "DMQ",
  CMT = "CMT",
  CMQ = "CMQ",
}

export enum DimensionsUnit {
  MTR = "MTR",
  CMT = "CMT",
  INH = "INH",
  FOT = "FOT",
}

export enum WeightSpecifier {
  NET = "NET",
  GROSS = "GROSS",
}

export interface DangerousGoodsDetails {
  unitedNationsNumber: string;
  properShippingName: string;
  unitedNationsClass: string;
  division: string;
  packingGroup: string;
  weight: number;
  volume: number;
  __typename: "DangerousGoodsDetails";
}

export enum TemperatureUnit {
  CEL = "CEL",
  FAH = "FAH",
}

export interface TemperatureRequirements {
  temperatureControlRequired: boolean;
  requiredMinimum: number;
  requiredMaximum: number;
  unit: TemperatureUnit;
  __typename: "TemperatureRequirements";
}

export interface CargoItem {
  packageType: PackageTypeCodes;
  packageNumber: number;
  stackable: boolean;
  topLoadable?: boolean;
  description: string;
  weightInput?: string;
  weight: number;
  weightUnit: WeightUnit;
  volume: number;
  volumeUnit: VolumeUnit;
  length: number;
  width: number;
  height: number;
  quantity: number;
  dimensionsUnit: DimensionsUnit;
  dangerousGoodsDetails: DangerousGoodsDetails;
  temperatureRequirements: TemperatureRequirements;
  weightSpecifier: WeightSpecifier;
  __typename: "BookingCargoItem";
}

export interface Reference {
  type: string;
  number: string;
  __typename: "Reference";
}

export enum BillOfLading {
  EXPRESS = "EXPRESS",
  ORIGINAL = "ORIGINAL",
}

export enum QueryType {
  CUSTOMER = "CUSTOMER",
  ADMIN = "ADMIN",
}

export enum BookingStatusUser {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  SENT = "SENT",
  CANCELLED = "CANCELLED",
}

export enum ServiceLevel {
  ECONOMY = "ECONOMY",
  CONNECT = "CONNECT",
  PRIORITY = "PRIORITY",
  AFP_ECONOMY = "AFP_ECONOMY",
  AFP_PRIORITY = "AFP_PRIORITY",
  FCL = "FCL",
  LCL = "LCL",
  BCN = "BCN",
}

export type CountriesList = Record<
  string,
  {
    name: string;
    code: string;
  }
>;

export interface ShipmentNotificationRecipients {
  notifyCreator: boolean;
  parties: PartyType[];
  others: string[];
}

export interface Booking {
  id: string;
  housebill?: string;
  corporatePartner?: string;
  quoteId?: string;
  product: Product;
  serviceLevel?: string | null;
  services?: string[];
  incoterms?: string;
  additionalInformation?: string;
  additionalRequirements?: string;
  parties?: Party[];
  commodityCode: string;
  cargoItems?: Partial<CargoItem>[];
  references?: Reference[];
  billOfLading?: BillOfLading;
  dateCreated: string;
  dateUpdated?: string;
  dateSent?: string;
  dateSubmitted?: string;
  dateEstimatedPickup?: string;
  consignmentUuid: string | null;
  paymentTerms?: PaymentTerm;
  bookingName?: string;
  distributionList?: string[];
  notificationRecipients?: ShipmentNotificationRecipients;
  source: string;
  isBcnShipment?: boolean;
  __typename: "Booking";
}

export enum AreaType {
  TERMINAL = "terminal",
  COMPANY = "company",
}

export enum DirectionType {
  IMPORT = "IMPORT",
  EXPORT = "EXPORT",
}

export enum CustomClearanceEnum {
  ICC = "ICC",
  ECC = "ECC",
}

export interface Service {
  serviceLevel?: string | null;
  services?: CustomClearanceEnum[];
  incoterms?: string;
  additionalInformation?: string;
  isBcnShipment?: boolean;
}

export interface RouteInput {
  shipper: OmitGraphQLTypename<Party>;
  consignee: OmitGraphQLTypename<Party>;
  pickup: OmitGraphQLTypename<Partial<Party>>;
  delivery: OmitGraphQLTypename<Partial<Party>>;
  notify: OmitGraphQLTypename<Party> | null;
  dateEstimatedPickup: string;
  additionalRequirements: string;
}

export interface FinalizeInput {
  references: Array<OmitGraphQLTypename<Reference>>;
  billOfLading?: BillOfLading;
  distributionList?: string[] | null;
  notificationRecipients?: ShipmentNotificationRecipients | null;
}

export interface BookingInput {
  quoteId?: string;
  product?: Product;
  serviceLevel?: string | null;
  services?: string[];
  incoterms?: string;
  additionalInformation?: string;
  additionalRequirements?: string;
  dateEstimatedPickup?: string;
  billOfLading?: BillOfLading;
  parties?: Array<OmitGraphQLTypename<Party>>;
  cargoItems?: Array<Partial<OmitGraphQLTypename<CargoItem>>>;
  references?: Array<OmitGraphQLTypename<Reference>>;
  commodityCode?: string;
  source?: string;
  bookingName?: string;
  distributionList?: string[];
  isBcnShipment?: boolean;
  notificationRecipients?: ShipmentNotificationRecipients;
}

// FIXME: Add typename field to new interfaces
export interface BookingAddressCoordinates {
  lat: number;
  lng: number;
}

export interface BookingAddress {
  addressLine: string;
  zipCode: string;
  areaName: string;
  city: string;
  geoCoordinates: BookingAddressCoordinates;
  inputType: AddressInputType;
  isResidential: boolean;
  iataCode: string;
  unloCode: string;
  countryCode: string;
}

export enum RatesType {
  MARKET = "MARKET",
  CONTRACTED = "CONTRACTED",
}

export interface BookingSummary {
  id: string;
  userId: string;
  userEmail: string;
  quoteId: string;
  housebill: string;
  product: Product;
  serviceLevel: string;
  statusUser: BookingStatusUser;
  references: string[];
  originCountry: string;
  originCity: string;
  destinationCountry: string;
  destinationCity: string;
  billingCountry: string;
  totalVolume: number;
  totalWeight: number;
  weightUom: string;
  volumeUom: string;
  dateCreated: Date;
  dateUpdated: Date;
  billingAccount: string;
  billingAddress: BookingAddress;
  consigneeAccount: string;
  consigneeAddress: BookingAddress;
  deliveryAccount: string;
  deliveryAddress: BookingAddress;
  deliveryHandlingStation: string;
  pickupAccount: string;
  pickupAddress: BookingAddress;
  pickupHandlingStation: string;
  shipperAccount: string;
  shipperAddress: BookingAddress;
  quoteTotal: null | number;
  ratesType: null | RatesType;
  serviceTotal: null | number;
  exportCustomsClearanceValue: null | number;
  importCustomsClearanceValue: null | number;
  sustainabilityOfferingValue: null | number;
  insuranceValue: null | number;
  followed: boolean | null;
  dateSent: Date;
  dateSubmitted: Date;
  shipperCompany: string;
  consigneeCompany: string;
  billingCompany: string;
  consignmentUuid: null | string;
  bookingName: null | string;
  currency: string;
  dateEstimatedPickup: string;
}

export enum DateFormat {
  DATE = "DATE",
  DATE_TIME = "DATE_TIME",
}

export interface ServicesInput {
  serviceLevel?: string | null;
  services?: string[] | null;
  incoterms?: string | null;
  isBcnShipment?: boolean;
  additionalInformation?: string | null;
}

export enum CargoCodes {
  CONTAINER = "Container",
  LOOSE_CARGO = "LooseCargo",
}

export const roundUpDecimals = {
  default: 2,
  volume: 3,
};
