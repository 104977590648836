import { CargoCodes, Product } from "constants/bookWorkflow";
import { Country } from "constants/masterDataManagement";
import { ReactComponent as ContainerIcon } from "assets/icons/QnB/cargo-types-container.svg";
import { ReactComponent as LooseCargoIcon } from "assets/icons/QnB/illustrations-chips-loose-cargo.svg";
import { ProgressStepsItem } from "components/templates/BookingStandalone/BookingProgress/BookingProgress";
import { ValidationErrors } from "final-form";
import { IntlShape } from "react-intl";
import { StepStateEnum } from "react-lib/es/atoms/ProgressStep/ProgressStep.constants";
import { StageSectionInfo } from "redux/makeABooking/constants";
import { BillingFormValues } from "./BookingBilling/BookingBilling.constants";
import { CargoFormValues } from "./BookingCargo/BookingCargo.constants";
import { FinalizeFormValues } from "./BookingFinalize/BookingFinalize.constants";
import { FormValues as RouteFormValues } from "./BookingRoute/BookingRoute.constants";
import { ServiceFormValues } from "./BookingService/BookingService.constants";

export const makeABookingPath = "/make-a-booking";

// FIXME: Move to root helpers since it is used in multiple features
export enum MakeABookingStage {
  MODE = "MODE",
  ROUTE = "ROUTE",
  CARGO = "CARGO",
  SERVICE = "SERVICE",
  BILLING = "BILLING",
  FINALIZE = "FINALIZE",
}

export enum SelectSizes {
  LARGE = "lg",
  MEDIUM = "md",
}

export const makeABookingRoutesPaths: Record<MakeABookingStage, string> = {
  [MakeABookingStage.MODE]: `${makeABookingPath}/:bookingId/mode`,
  [MakeABookingStage.ROUTE]: `${makeABookingPath}/:bookingId/route`,
  [MakeABookingStage.CARGO]: `${makeABookingPath}/:bookingId/cargo`,
  [MakeABookingStage.SERVICE]: `${makeABookingPath}/:bookingId/service`,
  [MakeABookingStage.BILLING]: `${makeABookingPath}/:bookingId/billing`,
  [MakeABookingStage.FINALIZE]: `${makeABookingPath}/:bookingId/finalize`,
};

export const makeABookingStartingPoint = `${makeABookingPath}/mode`;

export const BookingStandaloneXXLBreakpoint = "1440px";

export const bookingFormId = "bookingForm";

export interface ProgressStep extends ProgressStepsItem {
  key: MakeABookingStage;
}

export const progressStepsBase: Array<Omit<ProgressStep, "label">> = [
  {
    key: MakeABookingStage.MODE,
    state: StepStateEnum.UPCOMING,
    stepNumber: 1,
  },
  {
    key: MakeABookingStage.ROUTE,
    state: StepStateEnum.UPCOMING,
    stepNumber: 2,
  },
  {
    key: MakeABookingStage.CARGO,
    state: StepStateEnum.UPCOMING,
    stepNumber: 3,
  },
  {
    key: MakeABookingStage.SERVICE,
    state: StepStateEnum.UPCOMING,
    stepNumber: 4,
  },
  {
    key: MakeABookingStage.BILLING,
    state: StepStateEnum.UPCOMING,
    stepNumber: 5,
  },
  {
    key: MakeABookingStage.FINALIZE,
    state: StepStateEnum.UPCOMING,
    stepNumber: 6,
  },
];

export type ValidateBookingPayloadResponse = Array<{
  stage: MakeABookingStage;
  errors?: ValidationErrors;
  markInvalidSections: MarkInvalidSections;
  isVisited?: boolean;
}>;

export type GetStageValues =
  | Partial<{ mode?: Product }>
  | Partial<RouteFormValues>
  | Partial<BillingFormValues>
  | Partial<ServiceFormValues>
  | Partial<CargoFormValues>
  | Partial<FinalizeFormValues>;

export type MarkInvalidSections = (
  intl: IntlShape,
  errors: ValidationErrors
) => StageSectionInfo[];

export type FilteredCountriesLists = {
  destinationCountries: Country[];
  originCountries: Country[];
  billingCountries: Country[];
  unfilteredCountries: Country[];
};

export enum CargoOptionsWeblateKeys {
  ContainerTitle = "fullContainerLoadTitle",
  ContainerDescription = "fullContainerLoadDescription",
  LooseCargoTitle = "looseCargoTitle",
  LooseCargoDescription = "looseCargoDescription",
}

export const containerCargoOption = {
  code: CargoCodes.CONTAINER,
  titleWeblateKey: CargoOptionsWeblateKeys.ContainerTitle,
  descriptionWeblateKey: CargoOptionsWeblateKeys.ContainerDescription,
  icon: ContainerIcon,
};

export const looseCargoOption = {
  code: CargoCodes.LOOSE_CARGO,
  titleWeblateKey: CargoOptionsWeblateKeys.LooseCargoTitle,
  descriptionWeblateKey: CargoOptionsWeblateKeys.LooseCargoDescription,
  icon: LooseCargoIcon,
};
