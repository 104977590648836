import { useQuery } from "@apollo/client";
import AddressBook from "components/organisms/StreetAddress/AddressBook/AddressBook";
import { useContactUsLink } from "helpers/links";
import { useCorporatePartnersLoad } from "hooks/useCorporatePartnersLoad";
import useIsInternalUser from "hooks/useIsInternalUser";
import { GET_USER_DETAILS } from "queries/AAA/userQueries";
import React, { useMemo, useState } from "react";
import { Avatar } from "react-lib";
import { useDispatch, useSelector } from "react-redux";
import { setAppState } from "redux/app/actions";
import { getCorporatePartners } from "redux/userSettings/actions";
import { useFilteredCountryList } from "routes/MakeABooking/MakeABooking.helpers";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";
import { useMenuItems } from "./UserAvatarSection.helpers";
import * as S from "./UserAvatarSection.styles";

const UserAvatarSection = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isAddressBookModalOpen, setIsAddressBookModalOpen] = useState(false);
  const { unfilteredCountries } = useFilteredCountryList();

  const isDemoMode = useSelector<any, boolean>(
    (state) => state.userSettings.visibilityScope?.demo
  );

  const isSwitchingToUsersOrDemo = useSelector<any, boolean>(
    (state) => state.app.isCheckingDemoProfile
  );

  const corporatePartners = useSelector<any, any[]>(
    (state) => state.userSettings.corporatePartners
  );

  const { data: userData } = useQuery(GET_USER_DETAILS);
  const nameInitials: string = userData?.user?.nameInitials || "";

  const { hasDemoCPN, hasRealCPN } = useMemo(
    () => ({
      hasDemoCPN: corporatePartners.some(({ demo }) => demo),
      hasRealCPN: corporatePartners.some(({ demo }) => !demo),
    }),
    [corporatePartners]
  );

  useCorporatePartnersLoad();
  const contactUsLink = useContactUsLink();
  const isUserInternal = useIsInternalUser();
  const menuItems = useMenuItems(
    isUserInternal,
    contactUsLink,
    setIsChangePasswordModalOpen,
    setIsAddressBookModalOpen
  );

  const onUserModeChanged = () => {
    if (!isSwitchingToUsersOrDemo) {
      dispatch(setAppState("isCheckingDemoProfile", true));
      dispatch(getCorporatePartners({ isDemoMode: !isDemoMode }));
      setIsMenuOpen(false);
    }
  };

  return isSwitchingToUsersOrDemo ? null : (
    <>
      <S.UserAvatarWrapper>
        <div onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <Avatar nameInitials={nameInitials} isDemo={isDemoMode} />
        </div>
        <S.StyledFlyOut
          itemGroups={menuItems}
          onOutsideClick={() => setIsMenuOpen(false)}
          onVisiblityChange={setIsMenuOpen}
          visible={isMenuOpen}
          isTopBar
          hasDemoCPN={hasDemoCPN}
          hasRealCPN={hasRealCPN}
          onUserModeChanged={onUserModeChanged}
          email={userData?.user?.email}
          nameInitials={nameInitials}
        />
      </S.UserAvatarWrapper>
      {!isUserInternal && (
        <ChangePasswordModal
          isModalOpen={isChangePasswordModalOpen}
          onModalClose={() => setIsChangePasswordModalOpen(false)}
        />
      )}
      <AddressBook
        isAddressBookModalOpen={isAddressBookModalOpen}
        countryList={unfilteredCountries}
        closeAddressBookModal={() => setIsAddressBookModalOpen(false)}
        shouldDisplayOpenAddressBookModalButton={false}
      />
    </>
  );
};

export default UserAvatarSection;
