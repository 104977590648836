import { SortableField } from "constants/shipments";
import { PurchaseOrdersListsPaths } from "../PurchaseOrders.constants";

export const purchaseOrdersListsPaths = [
  PurchaseOrdersListsPaths.ORDERS,
  PurchaseOrdersListsPaths.ORDER_LINES,
];

export enum TableColumn {
  DETAILS = "Details",
  SUPPLIER = "SUPPLIER",
  BUYER = "BUYER",
  MILESTONES = "MILESTONES",
  RIGHT_SPACE = "RIGHT SPACE",
}

export const initialColumns: Array<TableColumn | SortableField> = [
  TableColumn.DETAILS,
  TableColumn.SUPPLIER,
  SortableField.PICKUP_COMBINED,
  SortableField.DEPARTURE_COMBINED,
  SortableField.ARRIVAL_COMBINED,
  SortableField.DELIVERY_COMBINED,
  TableColumn.RIGHT_SPACE,
];

export const swapableColumns: [TableColumn.SUPPLIER, TableColumn.BUYER] = [
  TableColumn.SUPPLIER,
  TableColumn.BUYER,
];

export interface TableProps<T> {
  rowData: T[];
  isLoading: boolean;
  refetchList?: () => void;
  totalOrderAmount?: number | null;
  noContentFallback?: React.ReactElement;
}

export const statusColumMinWidth = 90;
export const lastColumnMarginCompensation = 22;

export enum SharedTableColumn {
  ORDER = "ORDER",
  SUPPLIER = "SUPPLIER",
  BUYER = "BUYER",
  REQUIRED_CARGO_READY = "REQUIRED_CARGO_READY",
  SHIP_WINDOW = "SHIP_WINDOW",
  REQUIRED_DELIVERY = "REQUIRED_DELIVERY_SPACE",
  RIGHT_SPACE = "RIGHT_SPACE",
}

export interface OrderTableProps<T> {
  rowsData: T[];
  isLoading: boolean;
  isAnyFilterActive: boolean;
}

export interface OrderDate {
  date: string;
  isEstimated?: boolean;
}
