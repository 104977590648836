const SERVICES = {
  NOQT: "NOQT",
  PORTAL: "PORTAL",
  BOOK: "BOOK",
  DASHBOARD: "DASHBOARD",
  SHIPMENTS: "SHIPMENTS",
  REPORTS: "REPORTS",
  CUSTOMER_SUPPORT_CENTER: "CUSTOMER_SUPPORT_CENTER",
  ANALYTICS: "ANALYTICS",
  ORDERS: "ORDERS",
  QUOTES: "QUOTES",
};

export default SERVICES;
