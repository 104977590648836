import {
  BackendReportingFilter,
  FieldType,
  PreviewReportingFilter,
  ReportField,
  ReportingFilter,
  ReportingFilterGroup,
  ReportingFilterValue,
  ReportingWildcardFilterValue,
  StringCondition,
} from "constants/reporting";
import findIndex from "lodash/findIndex";
import fromPairs from "lodash/fromPairs";
import has from "lodash/has";
import pickBy from "lodash/pickBy";
import omitDeep from "omit-deep-lodash";
import { wildcardEscape, wildcardUnescape } from "./string";

export const isTimestampField = (field: ReportField): boolean =>
  field.type === FieldType.TIMESTAMP;

export const isTextField = (field: ReportField): boolean =>
  field.type === FieldType.TEXT;

export const isIntField = (field: ReportField): boolean =>
  field.type === FieldType.INT;

export const isFloatField = (field: ReportField): boolean =>
  field.type === FieldType.FLOAT;

export const isNumericField = (field: ReportField): boolean =>
  isIntField(field) || isFloatField(field);

export const cleanReportingFilters = (
  filters: ReportingFilter[]
): BackendReportingFilter[] =>
  filters.map((filter) => {
    const newFilter = omitDeep(filter, "__typename") as BackendReportingFilter;
    newFilter.filter_value = pickBy(
      newFilter.filter_value,
      (val) => val !== null
    );
    return newFilter;
  });

export const isDateRangeFilter = (filter: ReportingFilter): boolean =>
  filter.filter_value.type === FieldType.TIMESTAMP &&
  !filter.filter_value.contains &&
  !filter.filter_value.in &&
  !filter.filter_value.not &&
  !filter.filter_value.empty &&
  (!!filter.filter_value.current ||
    !!filter.filter_value.previous ||
    !!filter.filter_value.next ||
    (!!filter.filter_value.gte && !!filter.filter_value.lte) ||
    (!!filter.filter_value.lt && !!filter.filter_value.gt));

export const isFilterOk = (filter: ReportingFilter): boolean =>
  (filter.filter_value.in?.length ?? 0) > 0 ||
  (filter.filter_value.wildcard?.length ?? 0) > 0 ||
  typeof filter.filter_value?.empty === "boolean" ||
  isDateRangeFilter(filter);

export const convertReportingFilterFromFrontendToPreview = (
  filters: ReportingFilter[]
): Record<string, ReportingFilterValue> =>
  fromPairs(
    filters
      .filter(isFilterOk)
      .map((filter) => [filter.field, filter.filter_value])
  );

export const convertReportingFilterWithGroupsFromFrontendToPreview = (
  filters: ReportingFilter[]
): PreviewReportingFilter[] => {
  const groupIndexes = new Map<number, number>();
  const filterGroups: Record<string, ReportingFilterValue>[] = [];

  for (const filter of filters) {
    const group = filter.filter_group;

    if (isFilterOk(filter)) {
      if (group === null) {
        filterGroups.push({ [filter.field]: filter.filter_value });
      } else {
        const groupIndex = groupIndexes.get(group);
        if (groupIndex === undefined) {
          groupIndexes.set(group, filterGroups.length);
          filterGroups.push({ [filter.field]: filter.filter_value });
        } else {
          filterGroups[groupIndex][filter.field] = filter.filter_value;
        }
      }
    }
  }

  return filterGroups.map((filterGroup, index) => ({
    ...filterGroup,
    filterGroup: index,
  }));
};

export const getNextKey = (keys: number[]) => Math.max(-1, ...(keys ?? -1)) + 1;

export const getNextFilterGroupKeyFromFilters = (
  filters: ReportingFilter[]
): number => getNextKey(filters.map((filter) => filter.filter_group ?? -1));

export const getNextFilterGroupKeyFromGroups = (
  groups: ReportingFilterGroup[]
) => getNextKey(groups.map((group) => group.key));

interface AddFilterGroupToFiltersAccumulator {
  nextGroup: number;
  list: ReportingFilter[];
}

export const addFilterGroupToFilters = (
  filters: ReportingFilter[]
): ReportingFilter[] => {
  const reduceFunction = (
    { nextGroup, list }: AddFilterGroupToFiltersAccumulator,
    filter: ReportingFilter
  ): AddFilterGroupToFiltersAccumulator =>
    filter.filter_group === null
      ? {
          nextGroup: nextGroup + 1,
          list: [...list, { ...filter, filter_group: nextGroup + 1 }],
        }
      : {
          nextGroup,
          list: [...list, filter],
        };

  const result = filters.reduce<AddFilterGroupToFiltersAccumulator>(
    reduceFunction,
    {
      list: [],
      nextGroup: getNextFilterGroupKeyFromFilters(filters),
    }
  );

  return result.list;
};

const initialFilterValues: Record<FieldType, Partial<ReportingFilterValue>> = {
  [FieldType.INT]: { in: [] },
  [FieldType.FLOAT]: { in: [] },
  [FieldType.TIMESTAMP]: { empty: undefined },
  [FieldType.TEXT]: {},
};
export const initializeFilter = (
  column: ReportField
): BackendReportingFilter => ({
  field: column.name,
  filter_group: null,
  filter_value: {
    ...(initialFilterValues[column.type] || null),
    type: column.type,
  },
});
export const initializeFilterWithGroup = (
  existingFilters: ReportingFilter[],
  column: ReportField
): BackendReportingFilter => ({
  field: column.name,
  filter_group: getNextFilterGroupKeyFromFilters(existingFilters),
  filter_value: {
    ...(initialFilterValues[column.type] || null),
    type: column.type,
  },
});

export const getFilterCount = (filter: ReportingFilter): number => {
  if (!filter?.filter_value) {
    return 0;
  }

  if (filter.filter_value.in) {
    return filter.filter_value.in.length;
  }

  if (has(filter.filter_value, "wildcard")) {
    return 1;
  }

  if (typeof filter.filter_value.empty === "boolean") {
    return 1;
  }

  if (
    filter.filter_value.previous ||
    filter.filter_value.current ||
    filter.filter_value.next ||
    (filter.filter_value.gte && filter.filter_value.lte) ||
    (filter.filter_value.lt && filter.filter_value.gt)
  ) {
    return 1;
  }

  return 0;
};

export const updateFilterInList = (
  filters: ReportingFilter[],
  filter: ReportingFilter,
  field: string = filter.field
): ReportingFilter[] => {
  const newFilters =
    filter.field === field
      ? filters.slice()
      : filters.filter((f) => f.field !== filter.field);
  const index = findIndex(newFilters, { field });
  if (index === -1) {
    newFilters.push(filter);
  } else {
    newFilters[index] = filter;
  }
  return newFilters;
};

export const createStringFilterValue = (
  condition: StringCondition,
  inputValue: string,
  tags: string[]
): null | Partial<ReportingFilterValue> => {
  switch (condition) {
    case StringCondition.TAGS:
      return { in: tags };

    case StringCondition.EMPTY:
      return { empty: true };

    case StringCondition.NOT_EMPTY:
      return { empty: false };

    case StringCondition.CONTAINS:
      return { wildcard: `%${wildcardEscape(inputValue)}%` };

    case StringCondition.NOT_CONTAINS:
      return { wildcard: `%${wildcardEscape(inputValue)}%`, not: true };

    case StringCondition.STARTS_WITH:
      return { wildcard: `${wildcardEscape(inputValue)}%` };

    case StringCondition.ENDS_WITH:
      return { wildcard: `%${wildcardEscape(inputValue)}` };

    default:
      return null;
  }
};

export const createStringFilter = (
  filter: ReportingFilter,
  condition: StringCondition,
  inputValue: string,
  tags: string[]
): BackendReportingFilter => ({
  ...filter,
  filter_value: {
    type: FieldType.TEXT,
    ...createStringFilterValue(condition, inputValue, tags),
  },
});

export const getStringFilterCondition = (
  filterValue: ReportingFilterValue
): null | StringCondition => {
  if (filterValue.in) {
    return StringCondition.TAGS;
  }
  if (filterValue.empty === true) {
    return StringCondition.EMPTY;
  }
  if (filterValue.empty === false) {
    return StringCondition.NOT_EMPTY;
  }
  const isStartsWith = filterValue.wildcard?.endsWith("%");
  const isEndsWith = filterValue.wildcard?.startsWith("%");
  if (isStartsWith && isEndsWith) {
    return filterValue.not
      ? StringCondition.NOT_CONTAINS
      : StringCondition.CONTAINS;
  }
  if (isStartsWith) {
    return StringCondition.STARTS_WITH;
  }
  if (isEndsWith) {
    return StringCondition.ENDS_WITH;
  }
  return null;
};

export const getStringFilterInputValue = (
  filterValue: ReportingWildcardFilterValue,
  condition: StringCondition
): string => {
  switch (condition) {
    case StringCondition.NOT_CONTAINS:
    case StringCondition.CONTAINS:
      return wildcardUnescape(filterValue.wildcard.slice(1, -1));

    case StringCondition.STARTS_WITH:
      return wildcardUnescape(filterValue.wildcard.slice(0, -1));

    case StringCondition.ENDS_WITH:
      return wildcardUnescape(filterValue.wildcard.slice(1));

    default:
      return "";
  }
};

const noTags: string[] = [];
export const getStringFilterTags = (
  filterValue: ReportingFilterValue,
  condition: StringCondition
): string[] => {
  switch (condition) {
    case StringCondition.TAGS:
      return filterValue.in || noTags;

    default:
      return noTags;
  }
};
