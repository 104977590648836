import gql from "graphql-tag";

const GET_AEM_BY_LANGUAGE_AND_COUNTRY = gql`
  query GetAemByLanguageAndCountry($params: AEMParams!) {
    getAemByLanguageAndCountry(params: $params) {
      url_contact_us
      url_contact_us_unregistered
      url_legal_notice
      url_privacy_notice
      url_terms_of_use
      code_aem
      url_education_center
      url_news_webinars
      url_documentation_center
      url_global_service_alerts
      url_claims_complaints_compliments
    }
  }
`;

export default GET_AEM_BY_LANGUAGE_AND_COUNTRY;
