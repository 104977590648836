export const quoteAndBookPaths = [
  "/get-a-quote",
  "/offers",
  "/offers/:id",
  "/booking",
  "/booking/:step",
  "/continue-with-quote/:id",
  "/continue-with-quote",
];

export enum ShipmentsRoutes {
  SHIPMENTS = "/shipments",
  SHIPMENT_DETAILS = "/shipments/:id/:tab?",
  PRINT_SHIPMENT_DETAILS = "/shipments/print/:id/",
}

export enum SupportCenterRoutes {
  DASHBOARD = "/support",
  FAQ_CATEGORY_PAGE = "/support/categories/:id",
  FAQS_PAGE = "/support/faq",
  GLOSSARY = "/support/glossary",
  VIDEOS = "/support/videos",
  SEARCH_RESULTS = "/support/search",
}

export enum SupportCenterEndpoints {
  Categories = "support-center/categories",
  Category = "support-center/categories/",
  Videos = "support-center/videospage",
  ArticlesSearch = "support-center/articles/search",
  Articles = "support-center/articles",
}
