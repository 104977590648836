import { PartyType } from "constants/bookWorkflow";
import * as MDM from "constants/masterDataManagement";
import {
  AddressBookCustomerAddressesResponse,
  AddressFormContact,
  AddressFormValues,
} from "components/organisms/StreetAddress/AddressForm/AddressForm.constants";
import { getPhoneCountryPrefix } from "components/organisms/StreetAddress/AddressForm/AddressForm.helpers";
import { transformCountryCode } from "helpers/transformCountryCode";
import * as C from "./AddOrEditAddressForm.constants";

export const formatInitialData = (
  addressData: AddressFormValues | null,
  currentAddressData?: AddressBookCustomerAddressesResponse,
  countriesData?: MDM.Country[]
) => {
  if (currentAddressData) {
    const selectedCountry = countriesData?.find(
      (country) =>
        country.code ===
        currentAddressData.getAddressBookCustomerAddress?.address.country_code
    );

    const contacts =
      currentAddressData.getAddressBookCustomerAddress?.contacts.map(
        (contact) => ({
          contactId: contact.contact_id,
          contactName: contact.contact.contact_name,
          email: contact.contact.email,
          phoneNumber: contact.contact.phone,
          actionType: C.SectionActionType.NONE,
          modifiedAt: contact.audit.modified_at,
          createdAt: contact.audit.created_at,
          contactType: [
            ...(contact.contact.is_billing ? [PartyType.BILLING] : []),
            ...(contact.contact.is_consignee ? [PartyType.CONSIGNEE] : []),
            ...(contact.contact.is_delivery ? [PartyType.DELIVERY] : []),
            ...(contact.contact.is_pickup ? [PartyType.PICKUP] : []),
            ...(contact.contact.is_shipper ? [PartyType.SHIPPER] : []),
            ...(contact.contact.is_notify ? [PartyType.NOTIFY] : []),
          ],
        })
      );

    return {
      addressBook: {
        companyName:
          currentAddressData.getAddressBookCustomerAddress?.address
            .company_name,
        reference:
          currentAddressData.getAddressBookCustomerAddress?.address
            .ref_customer,
        address: {
          country: {
            code: currentAddressData.getAddressBookCustomerAddress?.address
              .country_code,
            countryCode: selectedCountry?.countryCode,
            label: selectedCountry?.label,
            value: selectedCountry?.label,
          },
          preferredAddressInputType: selectedCountry?.addressType,
          streetAddress:
            currentAddressData.getAddressBookCustomerAddress?.address
              .address_line1,
          street: "",
          zip: currentAddressData.getAddressBookCustomerAddress?.address
            .postal_code,
          city: currentAddressData.getAddressBookCustomerAddress?.address.city,
          states:
            currentAddressData.getAddressBookCustomerAddress?.address
              .state_province,
          addressId:
            currentAddressData.getAddressBookCustomerAddress?.address_id,
          modifiedAt:
            currentAddressData.getAddressBookCustomerAddress?.audit.modified_at,
          createdAt:
            currentAddressData.getAddressBookCustomerAddress?.audit.created_at,
        },
        contacts: contacts?.length
          ? contacts
          : [
              {
                ...C.initialContactsValue,
                phoneNumber: selectedCountry
                  ? getPhoneCountryPrefix(
                      transformCountryCode(selectedCountry.code)
                    )
                  : "",
              },
            ],
      },
    };
  }

  if (!addressData) {
    return null;
  }

  return {
    addressBook: {
      ...addressData.addressBook,
      contacts: addressData.addressBook.contacts.map((contact) => ({
        ...contact,
        actionType: C.SectionActionType.NONE,
      })),
    },
  };
};

export const filterContactData = (contacts: AddressFormContact[]) => {
  return contacts.filter(
    (contactSection) =>
      contactSection?.actionType !== C.SectionActionType.REMOVE
  );
};

export const prepareContactData = (contact: AddressFormContact) => ({
  contact_name: contact.contactName,
  email: contact.email,
  phone: contact.phoneNumber,
  is_shipper: contact?.contactType?.includes(PartyType.SHIPPER),
  is_consignee: contact?.contactType?.includes(PartyType.CONSIGNEE),
  is_pickup: contact?.contactType?.includes(PartyType.PICKUP),
  is_delivery: contact?.contactType?.includes(PartyType.DELIVERY),
  is_billing: contact?.contactType?.includes(PartyType.BILLING),
  is_notify: contact?.contactType?.includes(PartyType.NOTIFY),
});

export const prepareAddressData = (values: AddressFormValues, cp: string) => ({
  corporate_partner_uuid: cp,
  company_name: values.addressBook.companyName,
  address_line1: values.addressBook.address.streetAddress,
  address_line2: "",
  city: values.addressBook.address.city,
  postal_code: values.addressBook.address.zip,
  state_province: values.addressBook.address.states,
  country_code: values.addressBook.address.country.code,
  ref_customer: values.addressBook.reference,
  is_customer_visible: true,
});
