import {
  BillOfLading,
  DimensionsUnit,
  PaymentTerm,
  WeightUnit,
  VolumeUnit,
} from "constants/bookWorkflow";
import { defineMessages } from "react-intl";

export default defineMessages({
  mode: {
    id: "bookingStandalone.bookingSummary.mode",
    defaultMessage: "Mode",
  },
  route: {
    id: "bookingStandalone.bookingSummary.route",
    defaultMessage: "Route",
  },
  cargo: {
    id: "bookingStandalone.bookingSummary.cargo",
    defaultMessage: "Cargo",
  },
  packageTypeNotSelected: {
    id: "bookingStandalone.bookingSummary.cargo.noPackageTypeSelected",
    defaultMessage: "No package type selected",
  },
  service: {
    id: "bookingStandalone.bookingSummary.service",
    defaultMessage: "Service",
  },
  pickUp: {
    id: "bookingStandalone.bookingSummary.pickUp",
    defaultMessage: "Pick-up: {city}, {countryCode}",
  },
  requestedPickupDate: {
    id: "bookingStandalone.bookingSummary.requestedPickupDate",
    defaultMessage: "Requested pickup date: {date}",
  },
  selfDropoff: {
    id: "bookingStandalone.bookingSummary.selfDropoff",
    defaultMessage: "Self Drop off: {city}, {countryCode}",
  },
  requestedSelfDropoffDate: {
    id: "bookingStandalone.bookingSummary.requestedSelfDropoffDate",
    defaultMessage: "Requested Self drop off date: {date}",
  },
  selfCollection: {
    id: "bookingStandalone.bookingSummary.selfCollection",
    defaultMessage: "Self Collection: {city}, {countryCode}",
  },
  shipperDetails: {
    id: "bookingStandalone.bookingSummary.shipperDetails",
    defaultMessage: "Shipper: {city}, {countryCode}",
  },
  delivery: {
    id: "bookingStandalone.bookingSummary.delivery",
    defaultMessage: "Delivery: {city}, {countryCode}",
  },
  consigneeDetails: {
    id: "bookingStandalone.bookingSummary.consigneeDetails",
    defaultMessage: "Consignee: {city}, {countryCode}",
  },
  notifyDetails: {
    id: "bookingStandalone.bookingSummary.notifyDetails",
    defaultMessage: "Notify party: {city}, {countryCode}",
  },
  dimensions: {
    id: "bookingStandalone.bookingSummary.itemDimensions",
    defaultMessage:
      "Dimensions: {length} x {width} x {height} {dimensionsUnit}",
  },
  volume: {
    id: "bookingStandalone.bookingSummary.volume",
    defaultMessage: "Volume per item: {volume} {volumeUnit}",
  },
  weight: {
    id: "bookingStandalone.bookingSummary.weight",
    defaultMessage: "Weight per item: {weight} {weightUnit}",
  },
  netWeight: {
    id: "bookingStandalone.bookingSummary.netWeight",
    defaultMessage: "Net weight per container: {weight} {weightUnit}",
  },
  grossWeight: {
    id: "bookingStandalone.bookingSummary.grossWeight",
    defaultMessage: "Gross weight per container: {weight} {weightUnit}",
  },
  total: {
    id: "bookingStandalone.bookingSummary.total",
    defaultMessage: "Total",
  },
  totalPieces: {
    id: "bookingStandalone.bookingSummary.totalNumberOfPieces",
    defaultMessage: "Total number of pieces: {quantity}",
  },
  totalContainers: {
    id: "bookingStandalone.bookingSummary.totalNumberOfContainers",
    defaultMessage: "Total number of containers: {quantity}",
  },
  totalNetWeight: {
    id: "bookingStandalone.bookingSummary.totalNetWeight",
    defaultMessage: "Total net weight: {weight} {weightUnit}",
  },
  totalGrossWeight: {
    id: "bookingStandalone.bookingSummary.totalGrossWeight",
    defaultMessage: "Total gross weight: {weight} {weightUnit}",
  },
  totalWeight: {
    id: "bookingStandalone.bookingSummary.totalWeight",
    defaultMessage: "Total weight: {weight} {weightUnit}",
  },
  totalVolume: {
    id: "bookingStandalone.bookingSummary.totalVolume",
    defaultMessage: "Total volume: {volume} {volumeUnit}",
  },
  dangerousGoods: {
    id: "bookingStandalone.bookingSummary.dangerousGoods",
    defaultMessage: "DG",
  },
  [DimensionsUnit.CMT]: {
    id: "bookingStandalone.bookingSummary.dimensionsUnit.CMT",
    defaultMessage: "cm",
  },
  [DimensionsUnit.FOT]: {
    id: "bookingStandalone.bookingSummary.dimensionsUnit.FOT",
    defaultMessage: "ft",
  },
  [DimensionsUnit.INH]: {
    id: "bookingStandalone.bookingSummary.dimensionsUnit.INH",
    defaultMessage: "in",
  },
  [DimensionsUnit.MTR]: {
    id: "bookingStandalone.bookingSummary.dimensionsUnit.MTR",
    defaultMessage: "m",
  },
  [WeightUnit.KGM]: {
    id: "bookingStandalone.bookingSummary.weightUnit.KGM",
    defaultMessage: "kg",
  },
  [WeightUnit.GRM]: {
    id: "bookingStandalone.bookingSummary.weightUnit.GRM",
    defaultMessage: "g",
  },
  [WeightUnit.LBR]: {
    id: "bookingStandalone.bookingSummary.weightUnit.LBR",
    defaultMessage: "lb",
  },
  [WeightUnit.ONZ]: {
    id: "bookingStandalone.bookingSummary.weightUnit.ONZ",
    defaultMessage: "oz",
  },
  [WeightUnit.TNE]: {
    id: "bookingStandalone.bookingSummary.weightUnit.TNE",
    defaultMessage: "t",
  },
  [VolumeUnit.YDQ]: {
    id: "bookingStandalone.bookingSummary.volumeUnit.YDQ",
    defaultMessage: "yd³",
  },
  [VolumeUnit.MTQ]: {
    id: "bookingStandalone.bookingSummary.volumeUnit.MTQ",
    defaultMessage: "m³",
  },
  [VolumeUnit.LTR]: {
    id: "bookingStandalone.bookingSummary.volumeUnit.LTR",
    defaultMessage: "l",
  },
  [VolumeUnit.INQ]: {
    id: "bookingStandalone.bookingSummary.volumeUnit.INQ",
    defaultMessage: "in³",
  },
  [VolumeUnit.GLL]: {
    id: "bookingStandalone.bookingSummary.volumeUnit.GLL",
    defaultMessage: "gal",
  },
  [VolumeUnit.FTQ]: {
    id: "bookingStandalone.bookingSummary.volumeUnit.FTQ",
    defaultMessage: "ft³",
  },
  [VolumeUnit.DMQ]: {
    id: "bookingStandalone.bookingSummary.volumeUnit.DMQ",
    defaultMessage: "dm³",
  },
  [VolumeUnit.CMQ]: {
    id: "bookingStandalone.bookingSummary.volumeUnit.CMQ",
    defaultMessage: "m³",
  },
  serviceLevelNotSelected: {
    id: "bookingStandalone.bookingSummary.serviceLevel.noServiceLevelSelected",
    defaultMessage: "No service level selected",
  },
  serviceLevelLCL: {
    id: "bookingStandalone.bookingSummary.serviceLevel.LCL",
    defaultMessage: "DHL Ocean LCL",
  },
  serviceLevelFCL: {
    id: "bookingStandalone.bookingSummary.serviceLevel.FCL",
    defaultMessage: "DHL Ocean FCL",
  },
  serviceLevelBCN: {
    id: "bookingStandalone.bookingSummary.serviceLevel.BCN",
    defaultMessage: "DHL Ocean Buyer's Consolidation",
  },
  noCustomClearance: {
    id: "bookingStandalone.bookingSummary.customClearance.noCustomClearance",
    defaultMessage: "No Custom Clearance added",
  },
  exportOnly: {
    id: "bookingStandalone.bookingSummary.customClearance.exportOnly",
    defaultMessage: "Export Custom Clearance",
  },
  importOnly: {
    id: "bookingStandalone.bookingSummary.customClearance.importOnly",
    defaultMessage: "Import Custom Clearance",
  },
  serviceSelection: {
    id: "bookingStandalone.bookingSummary.serviceSelection",
    defaultMessage: "Service Selection",
  },
  customsClearance: {
    id: "bookingStandalone.bookingSummary.customsClearance",
    defaultMessage: "Customs Clearance",
  },
  incoterms: {
    id: "bookingStandalone.bookingSummary.incoterms",
    defaultMessage: "Incoterms",
  },
  furtherInformationsAndRequirements: {
    id: "bookingStandalone.bookingSummary.furtherInformationsAndRequirements",
    defaultMessage: "Further Informations & Requirements",
  },
  comment: {
    id: "bookingStandalone.bookingSummary.comment",
    defaultMessage: "Comment: ",
  },
  billing: {
    id: "bookingStandalone.bookingSummary.billing",
    defaultMessage: "Billing",
  },
  billingParty: {
    id: "bookingStandalone.bookingSummary.billingParty",
    defaultMessage: "Billing Party: {billingParty}",
  },
  [PaymentTerm.PREPAID]: {
    id: "bookingStandalone.bookingSummary.shipper",
    defaultMessage: "Shipper",
  },
  [PaymentTerm.COLLECT]: {
    id: "bookingStandalone.bookingSummary.consignee",
    defaultMessage: "Consignee",
  },
  [PaymentTerm.CROSSTRADE]: {
    id: "bookingStandalone.bookingSummary.thirdParty",
    defaultMessage: "Third Party",
  },
  finalize: {
    id: "bookingStandalone.bookingSummary.finalize",
    defaultMessage: "Finalize",
  },
  shipmentReference: {
    id: "bookingStandalone.bookingSummary.shipmentReference",
    defaultMessage: "Shipment Reference",
  },
  referenceId: {
    id: "bookingStandalone.bookingSummary.referenceId",
    defaultMessage: "Reference ID: {referenceId}",
  },
  referenceType: {
    id: "bookingStandalone.bookingSummary.referenceType",
    defaultMessage: "Type: {referenceType}",
  },
  billOfLadingType: {
    id: "bookingStandalone.bookingSummary.billOfLadingType",
    defaultMessage: "Bill of Lading Type",
  },
  [BillOfLading.EXPRESS]: {
    id: "bookingStandalone.bookingSummary.billOfLading.express",
    defaultMessage: "Express",
  },
  [BillOfLading.ORIGINAL]: {
    id: "bookingStandalone.bookingSummary.billOfLading.original",
    defaultMessage: "Original",
  },
  document: {
    id: "bookingStandalone.bookingSummary.document",
    defaultMessage: "Document",
  },
  fileName: {
    id: "bookingStandalone.bookingSummary.file",
    defaultMessage: "File: {fileName}",
  },
  documentType: {
    id: "bookingStandalone.bookingSummary.documentType",
    defaultMessage: "Type: {fileType}",
  },
});
