import { ServiceLevel, BookingStatusUser } from "constants/bookWorkflow";
import { defineMessages, MessageDescriptor } from "react-intl";

export const bookingsListHeaderMessagesObj = defineMessages({
  Follow: {
    id: "bookings.drafts.Follow",
    defaultMessage: "Follow",
  },
  Mode: {
    id: "bookingStandalone.drafts.Mode",
    defaultMessage: "Mode",
  },
  Service: {
    id: "bookingStandalone.drafts.Service",
    defaultMessage: "Service",
  },
  Housebill: {
    id: "bookingStandalone.drafts.Housebill",
    defaultMessage: "House Bill",
  },
  BookingName: {
    id: "bookingStandalone.drafts.BookingName",
    defaultMessage: "Booking Name",
  },
  QuoteID: {
    id: "bookingStandalone.drafts.QuoteID",
    defaultMessage: "Quotation ID",
  },
  BookingStatusUser: {
    id: "bookingStandalone.drafts.BookingStatusUser",
    defaultMessage: "Status",
  },
  OriginCountry: {
    id: "bookingStandalone.drafts.OriginCountry",
    defaultMessage: "Origin Country/Region",
  },
  DestinationCountry: {
    id: "bookingStandalone.drafts.DestinationCountry",
    defaultMessage: "Destination Country/Region",
  },
  BillingCountry: {
    id: "bookingStandalone.drafts.BillingCountry",
    defaultMessage: "Billing Country/Region",
  },
  OriginCity: {
    id: "bookingStandalone.drafts.OriginCity",
    defaultMessage: "Origin",
  },
  DestinationCity: {
    id: "bookingStandalone.drafts.DestinationCity",
    defaultMessage: "Destination",
  },
  ShipperCompany: {
    id: "bookingStandalone.drafts.ShipperCompany",
    defaultMessage: "Shipper",
  },
  ConsigneeCompany: {
    id: "bookingStandalone.drafts.ConsigneeCompany",
    defaultMessage: "Consignee",
  },
  BillingCompany: {
    id: "bookingStandalone.drafts.BillingCompany",
    defaultMessage: "Customer",
  },
  CreationDate: {
    id: "bookingStandalone.drafts.CreationDate",
    defaultMessage: "Creation Date",
  },
  UpdateDate: {
    id: "bookingStandalone.drafts.UpdateDate",
    defaultMessage: "Modified Date",
  },
  CreatedBy: {
    id: "bookingStandalone.drafts.CreatedBy",
    defaultMessage: "Created By",
  },
  References: {
    id: "bookingStandalone.drafts.CustomerReference",
    defaultMessage: "Your Reference",
  },
  Weight: {
    id: "bookingStandalone.drafts.Weight",
    defaultMessage: "Weight",
  },
  Volume: {
    id: "bookingStandalone.drafts.Volume",
    defaultMessage: "Volume",
  },
  QuoteTotal: {
    id: "bookingStandalone.drafts.QuoteTotal",
    defaultMessage: "Quote Total",
  },
  RatesType: {
    id: "bookingStandalone.drafts.RatesType",
    defaultMessage: "Rate Type",
  },
  ServiceTotal: {
    id: "bookingStandalone.drafts.ServiceTotal",
    defaultMessage: "Service Charge",
  },
  ExportCustomsClearanceValue: {
    id: "bookingStandalone.drafts.ExportCustomsClearanceValue",
    defaultMessage: "Export Customs Charge",
  },
  ImportCustomsClearanceValue: {
    id: "bookingStandalone.drafts.ImportCustomsClearanceValue",
    defaultMessage: "Import Customs Charge",
  },
  SustainabilityOfferingValue: {
    id: "bookingStandalone.drafts.SustainabilityOfferingValue",
    defaultMessage: "DHL Sustainability Offering Charge",
  },
  InsuranceValue: {
    id: "bookingStandalone.drafts.InsuranceValue",
    defaultMessage: "DHL Cargo Insurance/DHL Shipment Value Protection",
  },
  EstimatedPickupDate: {
    id: "bookingStandalone.drafts.EstimatedPickupDate",
    defaultMessage: "Pick-up/Self drop off date ",
  },
  SentDate: {
    id: "bookingStandalone.drafts.SentDate",
    defaultMessage: "Sent Date",
  },
  SubmittedDate: {
    id: "bookingStandalone.drafts.SubmittedDate",
    defaultMessage: "Submitted Date",
  },
});

export const serviceLevelMessages: Record<ServiceLevel, MessageDescriptor> =
  defineMessages({
    [ServiceLevel.ECONOMY]: {
      id: "bookingStandalone.serviceLevel.economy",
      defaultMessage: "DHL Air Economy",
    },
    [ServiceLevel.CONNECT]: {
      id: "bookingStandalone.serviceLevel.connect",
      defaultMessage: "DHL Air Connect",
    },
    [ServiceLevel.PRIORITY]: {
      id: "bookingStandalone.serviceLevel.priority",
      defaultMessage: "DHL Air Priority",
    },
    [ServiceLevel.AFP_ECONOMY]: {
      id: "bookingStandalone.serviceLevel.ple",
      defaultMessage: "DHL Airfreight Plus Economy",
    },
    [ServiceLevel.AFP_PRIORITY]: {
      id: "bookingStandalone.serviceLevel.plp",
      defaultMessage: "DHL Airfreight Plus Priority",
    },
    [ServiceLevel.FCL]: {
      id: "bookingStandalone.serviceLevel.fcl",
      defaultMessage: "DHL Ocean FCL",
    },
    [ServiceLevel.LCL]: {
      id: "bookingStandalone.serviceLevel.lcl",
      defaultMessage: "DHL Ocean LCL",
    },
    [ServiceLevel.BCN]: {
      id: "bookingStandalone.serviceLevel.bcn",
      defaultMessage: "DHL Ocean Buyer's Consolidation",
    },
  });

export const statusUserMessages: Record<BookingStatusUser, MessageDescriptor> =
  defineMessages({
    [BookingStatusUser.DRAFT]: {
      id: "bookingStandalone.bookingStatusUser.draft",
      defaultMessage: "Draft",
    },
    [BookingStatusUser.CANCELLED]: {
      id: "bookingStandalone.bookingStatusUser.cancelled",
      defaultMessage: "Cancelled",
    },
    [BookingStatusUser.SUBMITTED]: {
      id: "bookingStandalone.bookingStatusUser.submitted",
      defaultMessage: "Submitted",
    },
    [BookingStatusUser.SENT]: {
      id: "bookingStandalone.bookingStatusUser.sent",
      defaultMessage: "Sent",
    },
  });
