import SERVICES from "constants/services";
import { useQuery } from "@apollo/client";
import QUOTE_ALERTS from "queries/QnB/getQuoteAlerts";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getArray } from "utils/get";
import * as C from "./Alerts.constants";
import * as S from "./Alerts.styles";
import { messages } from "./messages";

const Alerts: React.FC<C.AlertsProps> = ({ service }) => {
  const [alertToShow, setAlertToShow] = useState<C.Alert>();
  const intl = useIntl();

  // Extended QnB API used to temporarily handle alerts for other services
  const { data: alertsForServiceData } = useQuery(QUOTE_ALERTS, {
    variables: {
      locale: localStorage.getItem("language") || intl.locale,
      service: service === SERVICES.QUOTES ? SERVICES.NOQT : service,
    },
  });

  useEffect(() => {
    const alerts: C.Alert[] = getArray(alertsForServiceData, "getQuoteAlerts");
    const filteredAlerts = alerts.filter(
      (item) => localStorage.getItem(item?.id) !== "confirmed"
    );
    if (filteredAlerts.length) setAlertToShow(filteredAlerts[0]);
  }, [alertsForServiceData, alertToShow, setAlertToShow]);

  return (
    (alertToShow && (
      <S.StyledAlertBanner
        description={alertToShow?.text}
        title={alertToShow?.title}
        linkToFollow={alertToShow?.url}
        okButtonText={intl.formatMessage(messages.okButton)}
        learnMoreText={intl.formatMessage(messages.learnMoreLink)}
        onOkClick={() => {
          if (alertToShow?.id) {
            localStorage.setItem(alertToShow?.id, "confirmed");
            setTimeout(() => {
              setAlertToShow(undefined);
            }, 200);
          }
        }}
      />
    )) ||
    null
  );
};

export default Alerts;
