export enum SectionActionType {
  ADD = "ADD",
  EDIT = "EDIT",
  REMOVE = "REMOVE",
  NONE = "NONE",
}

export const initialContactsValue = {
  contactName: "",
  email: "",
  phoneNumber: "",
  contactType: [],
  actionType: SectionActionType.ADD,
};

export const initialFormValues = {
  addressBook: {
    address: {
      country: {
        code: "",
        countryCode: "",
        label: "",
        value: "",
      },
      streetAddress: "",
      street: "",
      zip: "",
      city: "",
      states: "",
      preferredAddressInputType: "",
      companyName: "",
    },

    contacts: [initialContactsValue],
  },
};

export interface AddAddressResponse {
  createNewAddressBookCustomerAddress: {
    address_id: number;
    modified_at: string;
  };
}
