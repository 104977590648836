import { messages } from "components/molecules/Select/messages";
import { useGetDataTestId } from "containers/TestingContext/TestingContext";
import React, { useRef, useState } from "react";
import { Field } from "react-final-form";
import { useIntl } from "react-intl";
import { NewSelect, Size } from "react-lib";
import { ValidationVariants } from "react-lib/es/atoms/input/constants";
import * as S from "./SelectFieldWithValidation.styles";

interface SelectFieldWithValidationProps {
  name: string;
  isDisabled?: boolean;
  options: any[];
  onChange: (value: any) => void;
  onInputChange?: (value: any) => void;
  label?: string;
  placeholder?: string;
  size?: Size;
  className?: string;
  isScrolledOnInputChange?: boolean;
  initialValue?: string;
  isValidationVisible?: boolean;
  shouldShowErrorMessage?: boolean;
  isDefaultSortingDisabled?: boolean;
  isSearchable?: boolean;
  isLoading?: boolean;
  isMultiValueSelect?: boolean;
  validate?: (value: string) => string | undefined;
}

const SelectFieldWithValidation = ({
  name,
  isDisabled,
  options,
  onChange,
  onInputChange,
  label,
  placeholder,
  size = Size.LARGE,
  className,
  isScrolledOnInputChange = true,
  isValidationVisible,
  initialValue,
  shouldShowErrorMessage = true,
  isDefaultSortingDisabled,
  isSearchable,
  isLoading = false,
  isMultiValueSelect,
  validate,
}: SelectFieldWithValidationProps): JSX.Element => {
  const selectRef = useRef<NewSelect>(null);
  const [wasSelectVisited, setWasSelectVisited] = useState(false);
  const intl = useIntl();
  const getDataTestId = useGetDataTestId();

  return (
    <Field name={name} initialValue={initialValue} validate={validate}>
      {({ input, meta }) => {
        const shouldShowValidation: boolean =
          isValidationVisible ?? wasSelectVisited ?? false;
        return (
          <div
            data-testid={getDataTestId("SelectFieldWithValidation", `${name}`)}
          >
            <NewSelect
              onBlur={() => setWasSelectVisited(true)}
              value={input.value}
              selectRef={selectRef}
              isDisabled={isDisabled}
              placeholder={placeholder}
              size={size}
              options={options}
              onChange={onChange}
              isSearchable={isSearchable}
              onInputChange={(val: string) => {
                onInputChange?.(val);
                if (isScrolledOnInputChange) {
                  selectRef.current?.select.menuListRef?.scrollTo(0, 0);
                }
              }}
              label={label}
              className={className}
              validationVariant={
                shouldShowValidation && typeof meta.error !== "undefined"
                  ? ValidationVariants.INVALID
                  : undefined
              }
              isDefaultSortingDisabled={isDefaultSortingDisabled}
              isLoading={isLoading}
              noOptionsTranslation={intl.formatMessage(messages.noOptions)}
              isMultiValueSelect={isMultiValueSelect}
            />
            {shouldShowErrorMessage &&
              shouldShowValidation &&
              typeof meta.error === "string" && (
                <S.StyledHint>{meta.error}</S.StyledHint>
              )}
          </div>
        );
      }}
    </Field>
  );
};

export default SelectFieldWithValidation;
