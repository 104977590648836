import SERVICES from "constants/services";
import { startsWithAnyOf } from "./string";

const getActiveService = (path: string) => {
  const lowerCasePath = path?.toLowerCase();
  const isQuoteRoute = startsWithAnyOf(lowerCasePath, [
    "/get-a-quote",
    "/offers",
    "/booking/",
    "/terms-and-conditions",
    "/continue-with-quote",
  ]);
  const isBookingsRoute = startsWithAnyOf(lowerCasePath, ["/bookings"]);
  const isDashboardRoute = lowerCasePath === "/";
  const isShipmentsRoute = startsWithAnyOf(lowerCasePath, ["/shipments"]);
  const isReportsRoute = startsWithAnyOf(lowerCasePath, ["/reporting"]);
  const isCscRoute = startsWithAnyOf(lowerCasePath, ["/support"]);
  const isAnalyticsRoute = startsWithAnyOf(lowerCasePath, ["/analytics"]);
  const isOrdersRoute = startsWithAnyOf(lowerCasePath, ["/orders"]);
  const isQuotesRoute = startsWithAnyOf(lowerCasePath, ["/quotes"]);

  switch (true) {
    case isQuoteRoute:
      return SERVICES.NOQT;
    case isQuotesRoute:
      return SERVICES.QUOTES;
    case isBookingsRoute:
      return SERVICES.BOOK;
    case isDashboardRoute:
      return SERVICES.DASHBOARD;
    case isShipmentsRoute:
      return SERVICES.SHIPMENTS;
    case isReportsRoute:
      return SERVICES.REPORTS;
    case isCscRoute:
      return SERVICES.CUSTOMER_SUPPORT_CENTER;
    case isAnalyticsRoute:
      return SERVICES.ANALYTICS;
    case isOrdersRoute:
      return SERVICES.ORDERS;
    default:
      return SERVICES.PORTAL;
  }
};

export default getActiveService;
