import { Product } from "constants/bookWorkflow";
import {
  Country,
  PackageType,
  ProductData,
  Service,
  ReferenceType,
  FeatureFlag,
  Terminal,
  CommodityGroup,
  DocumentType,
  ContainerDescription,
  FullCargoPackageType,
  ExportType,
  DangerousGoodsClass,
  DangerousGoodsDivision,
  DangerousGoodsPackagingGroup,
} from "constants/masterDataManagement";
import {
  QueryHookOptions,
  QueryResult,
  OperationVariables,
  useQuery,
} from "@apollo/client";
import { transformCountryCode } from "helpers/transformCountryCode";
import {
  GET_COMMODITY_GROUPS,
  GET_COUNTRIES,
  GET_DOCUMENT_TYPES,
  GET_PACKAGE_TYPES,
  GET_PRODUCTS,
  GET_REFERENCE_TYPES,
  GET_SERVICES,
  GET_SERVICES_BY_PRODUCT_AND_COUNTRIES,
  GET_MYDHLI_FEATURE_FLAGS,
  GET_TERMINALS,
  GET_CONTAINERS_DESCRIPTIONS,
  GET_DANGEROUS_GOODS_CLASSES,
  GET_DANGEROUS_GOODS_DIVISIONS,
  GET_DANGEROUS_GOODS_PACKAGING_GROUPS,
} from "queries/AAA/masterDataManagement";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

interface CommonVariables {
  exportType?: ExportType;
  validOn?: string;
}

export const useMDMmyDHLiFeatureFlags = (): QueryResult<
  { getMyDHLiFeatureFlags: FeatureFlag[] },
  OperationVariables
> => {
  const cpUuid: string | undefined = useSelector(
    (state: RootState) => state?.userSettings?.visibilityScope?.uuid
  );
  const userEmail: string | undefined = useSelector(
    (state: RootState) => state?.auth?.loggedInUserEmail
  );

  const queryResult = useQuery<{ getMyDHLiFeatureFlags: FeatureFlag[] }>(
    GET_MYDHLI_FEATURE_FLAGS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        cpUuid,
        userEmail,
      },
    }
  );

  return queryResult;
};

interface CountriesResponse {
  getCountries: Country[];
}

export const useMDMCountries = (
  options?: QueryHookOptions<CountriesResponse, CommonVariables>
): undefined | Country[] => {
  const { data } = useQuery(GET_COUNTRIES, options);

  return data?.getCountries;
};

export const useTranslatedMDMCountries = (
  options?: QueryHookOptions<CountriesResponse, CommonVariables>
): Country[] => {
  const intl = useIntl();
  const countries = useMDMCountries(options);

  return useMemo<Country[]>(
    () =>
      (countries || []).map((country) => {
        const name = country.name_weblate_key
          ? intl.formatMessage({
              id: country.name_weblate_key,
              defaultMessage: country.label,
            })
          : country.label;

        const countryCode = transformCountryCode(country.code);

        return { ...country, label: `${name} (${countryCode})` };
      }),
    [countries, intl]
  );
};

interface ProductsResponse {
  getProducts: ProductData[];
}

export const useMDMProducts = (
  options?: QueryHookOptions<ProductsResponse, CommonVariables>
): undefined | ProductData[] => {
  const { data } = useQuery(GET_PRODUCTS, options);

  return data?.getProducts;
};

export const useMDMServices = (): undefined | Service[] => {
  const { data } = useQuery<{
    getServices: Service[];
  }>(GET_SERVICES);

  return data?.getServices;
};

export const useMDMServicesByProductAndCountries = (
  productCode: undefined | Product,
  pickupCountry: undefined | string,
  destinationCountry: undefined | string,
  exportType?: ExportType,
  validOn?: string,
  skip?: boolean
): undefined | Service[] => {
  const { data } = useQuery<{
    getServicesByProductAndCountries: Service[];
  }>(GET_SERVICES_BY_PRODUCT_AND_COUNTRIES, {
    variables: {
      product: productCode,
      pickupCountry,
      destinationCountry,
      exportType,
      validOn,
    },
    skip: !productCode || skip,
  });

  return data?.getServicesByProductAndCountries;
};

interface ReferencesResponse {
  getReferenceTypes?: ReferenceType[];
}

export const useMDMReferenceTypes = (
  options?: QueryHookOptions<ReferencesResponse, CommonVariables>
): undefined | ReferenceType[] => {
  const { data } = useQuery(GET_REFERENCE_TYPES, options);

  return data?.getReferenceTypes;
};

export const useMDMPackageType = (
  product: undefined | Product,
  exportType?: ExportType,
  validOn?: string,
  skip?: boolean
): undefined | PackageType[] => {
  const { data } = useQuery<{ getPackageTypes: PackageType[] }>(
    GET_PACKAGE_TYPES,
    {
      variables: { product, exportType, validOn },
      skip: !product || skip,
    }
  );

  return data?.getPackageTypes;
};

export const useMDMTerminals = (
  product: undefined | Product,
  country: undefined | string,
  unlocode?: string,
  exportType?: ExportType,
  validOn?: string
): Terminal[] | undefined => {
  const { data } = useQuery<{ getTerminals: Terminal[] }>(GET_TERMINALS, {
    variables: { product, country, unlocode, exportType, validOn },
    skip: !product || !country,
  });

  return data?.getTerminals;
};

export const useMDMCommodityGroups = (
  product: undefined | Product,
  exportType?: ExportType,
  validOn?: string
): undefined | CommodityGroup[] => {
  const { data } = useQuery<{
    getCommodityGroups: CommodityGroup[];
  }>(GET_COMMODITY_GROUPS, {
    variables: { product, exportType, validOn },
    skip: !product,
  });

  return data?.getCommodityGroups;
};

export const useMDMDocumentTypes = (
  product: undefined | Product,
  exportType?: ExportType,
  validOn?: string
): DocumentType[] => {
  const { data } = useQuery<{
    getDocumentType: DocumentType[];
  }>(GET_DOCUMENT_TYPES, {
    variables: { product, exportType, validOn },
    skip: !product,
  });

  return data?.getDocumentType ?? [];
};

export const useMDMContainersDescriptions = (
  containerCode?: FullCargoPackageType
): ContainerDescription[] | undefined => {
  const { data } = useQuery<{
    getContainersDescriptions: ContainerDescription[];
  }>(GET_CONTAINERS_DESCRIPTIONS, {
    variables: { containerCode },
  });

  return data?.getContainersDescriptions;
};

export const useMDMDangerousGoodsData = () => {
  const { data: divisions } = useQuery<{
    getDangerousGoodsDivision: DangerousGoodsDivision[];
  }>(GET_DANGEROUS_GOODS_DIVISIONS);

  const { data: classes } = useQuery<{
    getDangerousGoodsClasses: DangerousGoodsClass[];
  }>(GET_DANGEROUS_GOODS_CLASSES);

  const { data: packagingGroups } = useQuery<{
    getDangerousGoodsPackagingGroups: DangerousGoodsPackagingGroup[];
  }>(GET_DANGEROUS_GOODS_PACKAGING_GROUPS);

  return useMemo(
    () => ({
      classes: classes?.getDangerousGoodsClasses,
      packagingGroups: packagingGroups?.getDangerousGoodsPackagingGroups,
      divisions: divisions?.getDangerousGoodsDivision,
    }),
    [
      classes?.getDangerousGoodsClasses,
      divisions?.getDangerousGoodsDivision,
      packagingGroups?.getDangerousGoodsPackagingGroups,
    ]
  );
};
