import gql from "graphql-tag";

const BookingFragment = gql`
  fragment BookingFields on Booking {
    id
    housebill
    corporatePartner {
      id
      name
    }
    quoteId
    product
    serviceLevel
    services
    incoterms
    additionalInformation
    additionalRequirements
    bookingName
    parties {
      type
      referredType
      accountNumber
      companyName
      address {
        addressLine1
        addressLine2
        addressBookId
        isCustomerVisible
        countryCode
        postalCode
        city
        stateProvince
        preferredAddressInputType
        addressBookId
        isCustomerVisible
      }
      contactDetails {
        name
        phone
        email
        contactId
      }
      useTerminal
      terminalCode
      handlingStation
      handlingStationCountry
    }
    commodityCode
    cargoItems {
      packageType
      packageNumber
      stackable
      topLoadable
      description
      weight
      weightUnit
      volume
      volumeUnit
      length
      width
      height
      dimensionsUnit
      dangerousGoodsDetails {
        properShippingName
        unitedNationsNumber
        unitedNationsClass
        division
        packingGroup
        weight
        volume
      }
      temperatureRequirements {
        temperatureControlRequired
        requiredMinimum
        requiredMaximum
        unit
      }
    }
    references {
      type
      number
    }
    billOfLading
    dateCreated
    dateUpdated
    dateSent
    dateSubmitted
    dateEstimatedPickup
    distributionList
    notificationRecipients {
      notifyCreator
      parties
      others
    }
    paymentTerms
    consignmentUuid
    isBcnShipment
  }
`;

const BookingFragmentOld = gql`
  fragment BookingFields on Booking {
    id
    housebill
    corporatePartner {
      id
      name
    }
    quoteId
    product
    serviceLevel
    services
    incoterms
    additionalInformation
    additionalRequirements
    bookingName
    parties {
      type
      referredType
      accountNumber
      companyName
      address {
        addressLine1
        addressLine2
        addressBookId
        isCustomerVisible
        countryCode
        postalCode
        city
        stateProvince
        preferredAddressInputType
        addressBookId
        isCustomerVisible
      }
      contactDetails {
        name
        phone
        email
      }
      useTerminal
      terminalCode
      handlingStation
      handlingStationCountry
    }
    commodityCode
    cargoItems {
      packageType
      packageNumber
      stackable
      topLoadable
      description
      weight
      weightUnit
      volume
      volumeUnit
      length
      width
      height
      dimensionsUnit
      dangerousGoodsDetails {
        properShippingName
        unitedNationsNumber
        unitedNationsClass
        division
        packingGroup
        weight
        volume
      }
      temperatureRequirements {
        temperatureControlRequired
        requiredMinimum
        requiredMaximum
        unit
      }
    }
    references {
      type
      number
    }
    billOfLading
    dateCreated
    dateUpdated
    dateSent
    dateSubmitted
    dateEstimatedPickup
    distributionList
    notificationRecipients {
      notifyCreator
      parties
      others
    }
    paymentTerms
    consignmentUuid
  }
`;

export const CREATE_DRAFT = (isNewViewEnabled: boolean) => gql`
  ${isNewViewEnabled ? BookingFragment : BookingFragmentOld}
  mutation createDraft(
    $product: Product!
    $locale: String!
    $source: String!
    $clientId: String!
  ) {
    createDraft(
      request: {
        product: $product
        locale: $locale
        source: $source
        clientId: $clientId
      }
    ) {
      ...BookingFields
    }
  }
`;

export const GET_BOOKING_BY_ID = (isNewViewEnabled: boolean) => gql`
  ${isNewViewEnabled ? BookingFragment : BookingFragmentOld}
  query booking($id: ID!) {
    booking(id: $id) {
      ...BookingFields
      source
    }
  }
`;

export const UPDATE_SERVICES = (isNewViewEnabled: boolean) => gql`
  ${isNewViewEnabled ? BookingFragment : BookingFragmentOld}
  mutation updateServices($id: ID!, $services: ServicesInput!) {
    updateServices(id: $id, services: $services) {
      ...BookingFields
    }
  }
`;

export const UPDATE_BILLING = (isNewViewEnabled: boolean) => gql`
  ${isNewViewEnabled ? BookingFragment : BookingFragmentOld}
  mutation updateBilling($id: ID!, $billingParty: PartyInput!) {
    updateBilling(id: $id, billingParty: $billingParty) {
      ...BookingFields
    }
  }
`;

export const UPDATE_ROUTE = (isNewViewEnabled: boolean) => gql`
  ${isNewViewEnabled ? BookingFragment : BookingFragmentOld}
  mutation updateRoute($id: ID!, $route: RouteInput!) {
    updateRoute(id: $id, route: $route) {
      ...BookingFields
    }
  }
`;

export const UPDATE_CARGO = (isNewViewEnabled: boolean) => gql`
  ${isNewViewEnabled ? BookingFragment : BookingFragmentOld}
  mutation updateCargo($id: ID!, $cargoDetails: CargoDetailsInput!) {
    updateCargo(id: $id, cargoDetails: $cargoDetails) {
      ...BookingFields
    }
  }
`;

export const FINALIZE_BOOKING = (isNewViewEnabled: boolean) => gql`
  ${isNewViewEnabled ? BookingFragment : BookingFragmentOld}
  mutation finalizeBooking($id: ID!, $request: FinalizeBookingInput!) {
    finalizeBooking(id: $id, request: $request) {
      ...BookingFields
    }
  }
`;

export const SUBMIT_BOOKING = (isNewViewEnabled: boolean) => gql`
  ${isNewViewEnabled ? BookingFragment : BookingFragmentOld}
  mutation submitBooking($id: ID!, $locale: String!) {
    submitBooking(id: $id, locale: $locale) {
      ...BookingFields
    }
  }
`;

export const GET_BOOKING_LIST = gql`
  query GetBookingsList($filter: BookingFilter!) {
    bookingList(filter: $filter) {
      values
      bookings {
        id
        userId
        userEmail
        quoteId
        housebill
        bookingName
        product
        serviceLevel
        statusUser
        references
        originCountry
        originCity
        destinationCountry
        destinationCity
        billingCountry
        totalVolume
        totalWeight
        weightUom
        volumeUom
        dateCreated
        dateUpdated
        quoteTotal
        ratesType
        dateEstimatedPickup
        serviceTotal
        exportCustomsClearanceValue
        importCustomsClearanceValue
        sustainabilityOfferingValue
        insuranceValue
        billingAccount
        billingAddress {
          addressLine1
        }
        consigneeAccount
        consigneeAddress {
          addressLine1
        }
        deliveryAccount
        deliveryAddress {
          addressLine1
        }
        deliveryHandlingStation
        pickupAccount
        pickupAddress {
          addressLine1
        }
        pickupHandlingStation
        shipperAccount
        shipperAddress {
          addressLine1
        }
        followed
        dateSent
        dateSubmitted
        consigneeCompany
        shipperCompany
        billingCompany
        consignmentUuid
        currency
      }
      totalResultsNumber
    }
  }
`;

export const CREATE_BOOKING = (isNewViewEnabled: boolean) => gql`
  ${isNewViewEnabled ? BookingFragment : BookingFragmentOld}
  mutation createBooking($booking: BookingInput!) {
    createBooking(request: $booking) {
      ...BookingFields
    }
  }
`;

export const EXPORT_BOOKING_LIST = gql`
  query ExportBookingList($filter: BookingFilter!) {
    exportBookingList(filter: $filter) {
      documentId
    }
  }
`;

export const DELETE_BOOKING = gql`
  mutation deleteBooking($id: ID!) {
    deleteBooking(id: $id) {
      id
      message
    }
  }
`;
