import {
  eventContentButtonInterObj,
  eventContentCheckboxSelectInterObj,
  eventContentInputFieldInterObj,
  eventContentSelectFieldInterObj,
} from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "SaveYourSelectionInteractions";

export const SaveYourSelectionInteractions = {
  PRIVATE_CUSTOMER: {
    id: `${prefix}_Private Customer`,
    name: "Private Customer",
  },
  BUSINESS_CUSTOMER: {
    id: `${prefix}_Business Customer`,
    name: "Business Customer",
  },
  CONTINUE: { id: `${prefix}_Continue`, name: "Continue" },
  ROUTE_EDIT: { id: `${prefix}_Route Edit`, name: "Edit Button" },
  DROP_OFF_DATE: { id: `${prefix}_DropOff Date`, name: "Date Picker" },
  CONTRACT_ID: { id: `${prefix}_Contract Id`, name: "Contract ID" },
  CURRENCY: { id: `${prefix}_Currency`, name: "Currency" },
  NAME: { id: `${prefix}_Name`, name: "Name" },
  WORK_EMAIL: { id: `${prefix}_Work Email`, name: "Work Email" },
  JOB_TITLE: { id: `${prefix}_Job Title`, name: "Job Title" },
  DEPARTMENT: { id: `${prefix}_Department`, name: "Department" },
  SENIORITY: { id: `${prefix}_Seniority`, name: "Seniority" },
  PHONE_NUMBER: { id: `${prefix}_Phone Number`, name: "Phone Number" },
  COMPANY_NAME: { id: `${prefix}_Company Name`, name: "Company Name" },
  COMPANY_WEBSITE: { id: `${prefix}_Company Website`, name: "Company Website" },
  INDUSTRY_SECTOR: { id: `${prefix}_Industry Sector`, name: "Industry-Sector" },
  AVG_NUMB_OF_SHIPMENTS: {
    id: `${prefix}_Average Number Of Shipments`,
    name: "Average Number Of Shipments",
  },
  MARKETING_OPT_IN: {
    id: `${prefix}_Marketing Opt-In`,
    name: "Marketing Opt-In",
  },
};

const saveYourSelectionPosition = "Header";
const filtersPosition = "Filters";
const formPosition = "Form";
const quoteSummaryPosition = "Quote Summary";

const saveYourSelectionContext = "Save your Selection";
const saveQuoteContext = "Save Quote";

export const saveYourSelectionEventNameToEventContentMapper: C.EventNameToContentMapperType<
  typeof SaveYourSelectionInteractions
> = {
  [SaveYourSelectionInteractions.PRIVATE_CUSTOMER.id]: {
    name: SaveYourSelectionInteractions.PRIVATE_CUSTOMER.name,
    ...eventContentButtonInterObj,
    position: saveYourSelectionPosition,
    context: saveYourSelectionContext,
  },
  [SaveYourSelectionInteractions.BUSINESS_CUSTOMER.id]: {
    name: SaveYourSelectionInteractions.BUSINESS_CUSTOMER.name,
    ...eventContentButtonInterObj,
    position: saveYourSelectionPosition,
    context: saveYourSelectionContext,
  },
  [SaveYourSelectionInteractions.MARKETING_OPT_IN.id]: {
    name: SaveYourSelectionInteractions.MARKETING_OPT_IN.name,
    ...eventContentCheckboxSelectInterObj,
    position: formPosition,
    context: saveQuoteContext,
  },
  [SaveYourSelectionInteractions.CONTINUE.id]: {
    name: SaveYourSelectionInteractions.CONTINUE.name,
    ...eventContentButtonInterObj,
    position: formPosition,
    context: saveQuoteContext,
  },
  [SaveYourSelectionInteractions.ROUTE_EDIT.id]: {
    name: SaveYourSelectionInteractions.ROUTE_EDIT.name,
    ...eventContentButtonInterObj,
    position: quoteSummaryPosition,
    context: saveQuoteContext,
  },
  [SaveYourSelectionInteractions.DROP_OFF_DATE.id]: {
    name: SaveYourSelectionInteractions.DROP_OFF_DATE.name,
    ...eventContentSelectFieldInterObj,
    position: filtersPosition,
    context: saveQuoteContext,
  },
  [SaveYourSelectionInteractions.CONTRACT_ID.id]: {
    name: SaveYourSelectionInteractions.CONTRACT_ID.name,
    ...eventContentInputFieldInterObj,
    position: filtersPosition,
    context: saveQuoteContext,
  },
  [SaveYourSelectionInteractions.CURRENCY.id]: {
    name: SaveYourSelectionInteractions.CURRENCY.name,
    ...eventContentSelectFieldInterObj,
    position: filtersPosition,
    context: saveQuoteContext,
  },
  [SaveYourSelectionInteractions.COMPANY_NAME.id]: {
    name: SaveYourSelectionInteractions.COMPANY_NAME.name,
    ...eventContentInputFieldInterObj,
    position: formPosition,
    context: saveQuoteContext,
  },
  [SaveYourSelectionInteractions.NAME.id]: {
    name: SaveYourSelectionInteractions.NAME.name,
    ...eventContentInputFieldInterObj,
    position: formPosition,
    context: saveQuoteContext,
  },
  [SaveYourSelectionInteractions.WORK_EMAIL.id]: {
    name: SaveYourSelectionInteractions.WORK_EMAIL.name,
    ...eventContentInputFieldInterObj,
    position: formPosition,
    context: saveQuoteContext,
  },
  [SaveYourSelectionInteractions.PHONE_NUMBER.id]: {
    name: SaveYourSelectionInteractions.PHONE_NUMBER.name,
    ...eventContentInputFieldInterObj,
    position: formPosition,
    context: saveQuoteContext,
  },
};
