import { Party, PartyType } from "constants/bookWorkflow";
import { streetAddressValidationSchema } from "components/organisms/StreetAddress/StreetAddress.constants";
import { getDefaultJoiErrorFunction } from "helpers/joiValidation";
import joi from "joi";
import { IntlShape } from "react-intl";

export interface BillingFormValues {
  party: OmitGraphQLTypename<Party>;
}

export const partyGroupFieldPrefix = "party";

export const getBillingJoiSchema = (intl: IntlShape) =>
  joi
    .object({
      party: joi
        .object({
          type: joi.string().valid(...Object.values(PartyType)),
          referredType: joi.string().valid(...Object.values(PartyType)),
          useTerminal: joi.boolean(),
          terminalCode: joi.string().allow(null, ""),
        })
        .concat(streetAddressValidationSchema)
        .required(),
    })
    .options({ abortEarly: false, allowUnknown: true })
    .error(getDefaultJoiErrorFunction(intl));

export enum BillingStageSections {
  BILLING_PARTY = "billing party",
  BILLING_DETAILS = "billing details",
}
