import { CargoCodes, Party, PartyType, Product } from "constants/bookWorkflow";
import * as MDM from "constants/masterDataManagement";
import { useQuery } from "@apollo/client";
import { getOfrCargoCodes } from "helpers/bookWorkflow";
import { useMDMTerminals } from "hooks/masterDataManagement";
import { useBookWorkflowData } from "hooks/useBookWorkflowData";
import {
  GET_COUNTRIES,
  GET_COUNTRIES_PRODUCTS,
} from "queries/AAA/masterDataManagement";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { StepStateEnum } from "react-lib/es/atoms/ProgressStep/ProgressStep.constants";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getCurrentStep,
  getStagesValidationStatus,
} from "redux/makeABooking/selectors";
import * as C from "./MakeABooking.constants";
import messages from "./MakeABooking.messages";
import {
  ExternalSubmitEventDetails,
  ExternalSubmitEvent,
} from "./MakeABookingForm/MakeABookingForm.constants";

// FIXME: Move to root helpers since it is used in multiple features
export const buildMakeABookingPath = (page: C.MakeABookingStage, id: string) =>
  `${C.makeABookingPath}/${id}/${page.toLowerCase()}`;

const getStepState = (
  stepName: C.MakeABookingStage,
  currentStep: C.MakeABookingStage | undefined,
  currentPath: string,
  pagesValidationStatus: Partial<Record<C.MakeABookingStage, boolean>>
) => {
  if (currentPath === C.makeABookingStartingPoint) {
    if (stepName !== C.MakeABookingStage.MODE) {
      return StepStateEnum.DISABLED;
    }

    return StepStateEnum.CURRENT;
  }

  if (currentStep === stepName) {
    return StepStateEnum.CURRENT;
  }

  const wasStepTouched = pagesValidationStatus[stepName] !== undefined;

  if (wasStepTouched && pagesValidationStatus[stepName]) {
    return StepStateEnum.FINISHED;
  }

  return wasStepTouched && !pagesValidationStatus[stepName]
    ? StepStateEnum.PARTIAL
    : StepStateEnum.UPCOMING;
};

export const useProgressStepsState = () => {
  const intl = useIntl();
  const location = useLocation();
  const pagesValidationStatus = useSelector(
    getStagesValidationStatus,
    shallowEqual
  );
  const currentStep = useSelector(getCurrentStep);
  return C.progressStepsBase.map((step) => ({
    ...step,
    state: getStepState(
      step.key,
      currentStep,
      location.pathname,
      pagesValidationStatus
    ),
    label: intl.formatMessage(messages[step.key]),
  }));
};

export const dispatchSubmitEvent = (
  eventDetails: ExternalSubmitEventDetails
) => {
  const bookingForm = document.getElementById(
    C.bookingFormId
  ) as HTMLFormElement;

  const submitEvent: ExternalSubmitEvent = new CustomEvent("submit", {
    cancelable: true,
    bubbles: true,
    detail: eventDetails,
  });

  bookingForm?.dispatchEvent(submitEvent);
};

const countryCodeToProductFactory = (
  countriesProducts: MDM.CountryProduct[]
) => {
  const map = new Map();
  countriesProducts.forEach((countryProduct) => {
    const { countryCode, productCode } = countryProduct;
    map.set(
      countryCode,
      map.has(countryCode)
        ? {
            ...map.get(countryCode),
            [productCode]: countryProduct,
          }
        : {
            [productCode]: countryProduct,
          }
    );
  });
  return map;
};

export const useFilteredCountryList = (): C.FilteredCountriesLists => {
  const { product } = useBookWorkflowData();

  const { data: countriesData } =
    useQuery<{ getCountries: MDM.Country[] }>(GET_COUNTRIES);
  const { data: countriesProductData } = useQuery<{
    getCountriesProducts: MDM.CountryProduct[];
  }>(GET_COUNTRIES_PRODUCTS);

  const countryList = useMemo(
    () => countriesData?.getCountries || [],
    [countriesData]
  );
  const countryCodeToProduct = useMemo(
    () =>
      countryCodeToProductFactory(
        countriesProductData?.getCountriesProducts || []
      ),
    [countriesProductData]
  );

  return useMemo(
    () =>
      (countriesData?.getCountries || []).reduce<C.FilteredCountriesLists>(
        (acc, country) => {
          const countryProduct = countryCodeToProduct.get(country.countryCode);
          if (countryProduct == null || !product) return acc;

          if (countryProduct[product]?.isOrigin)
            acc.originCountries.push(country);
          if (countryProduct[product]?.isDestination)
            acc.destinationCountries.push(country);
          if (countryProduct[product]?.isBilling)
            acc.billingCountries.push(country);

          return acc;
        },
        {
          destinationCountries: [],
          originCountries: [],
          billingCountries: [],
          unfilteredCountries: countryList,
        }
      ),
    [countriesData?.getCountries, countryList, countryCodeToProduct, product]
  );
};

const getOfrCargoOptions = (
  pickup?: OmitGraphQLTypename<Party>,
  delivery?: OmitGraphQLTypename<Party>,
  pickupTerminals?: MDM.Terminal[],
  deliveryTerminals?: MDM.Terminal[]
) => {
  const cargoCodes = getOfrCargoCodes(
    pickup,
    delivery,
    pickupTerminals,
    deliveryTerminals
  );

  return cargoCodes?.map((code) =>
    code === CargoCodes.CONTAINER ? C.containerCargoOption : C.looseCargoOption
  );
};

const getCargoOptions = (
  product?: Product,
  pickup?: OmitGraphQLTypename<Party>,
  delivery?: OmitGraphQLTypename<Party>,
  pickupTerminals?: MDM.Terminal[],
  deliveryTerminals?: MDM.Terminal[]
) => {
  switch (product) {
    case Product.AFR:
      return [C.looseCargoOption];
    case Product.OFR:
      return getOfrCargoOptions(
        pickup,
        delivery,
        pickupTerminals,
        deliveryTerminals
      );
    default:
      return [];
  }
};

export const useCargoOptions = () => {
  const { product, parties } = useBookWorkflowData();
  const pickup = parties?.find(({ type }) => type === PartyType.PICKUP);
  const delivery = parties?.find(({ type }) => type === PartyType.DELIVERY);

  const pickupTerminals = useMDMTerminals(
    product,
    pickup?.address?.countryCode
  );
  const deliveryTerminals = useMDMTerminals(
    product,
    delivery?.address?.countryCode
  );

  return getCargoOptions(
    product,
    pickup,
    delivery,
    pickupTerminals,
    deliveryTerminals
  );
};
